@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    @apply border border-black rounded-xl px-5 py-1;
  }
}

.sidebar_menu ul li:hover {
  color: #fff;
  background-color: #c91414;
  border-radius: 15px;
  width: 200px;
}


/* Header */
.header-top {
  background: none;
  border-bottom: 1px solid var(--white-color-opacity-four);
  color: #fff;
  padding-bottom: var(--padding-bottom-10);
  padding-top: var(--padding-top-10);
  position: relative;
}

.auto-container {
  max-width: 1780px;
  padding: 0 20px;
}

.header-top .top-left {
  float: left;
  line-height: 30px;
  position: relative;
}

.header-top .top-info {
  position: relative;
}

.header-top .top-info li {
  color: #fff;
  float: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-right: 70px;
  position: relative;
}

.header-top .top-info li .icon {
  padding-right: var(--padding-right-5);
  position: relative;
}

.header-top .top-right {
  float: right;
  line-height: 30px;
  position: relative;
}

.header-top .top-info {
  position: relative;
}

.header-top .top-info li {
  color: #fff;
  float: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-right: 70px;
  position: relative;
}



/* MENU pages */
figure {
  width: 100%;
  /* height: 100%; */
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}
.hover14 figure {
  position: relative;
}
.hover14 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.hover14 figure:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}

.arif{
  border-bottom: 1px solid #fff;
}

@media (max-width: 767px) {
   .header-top{
    display: none;
   }
   .arif{
    display: none;
   }


}
/* Modal login */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; 
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  border: 1px solid #c91414;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}



